import { render, staticRenderFns } from "./CountChip.vue?vue&type=template&id=7018d71e&scoped=true&"
import script from "./CountChip.vue?vue&type=script&lang=js&"
export * from "./CountChip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7018d71e",
  null
  
)

export default component.exports