import { render, staticRenderFns } from "./ExportFormatButton.vue?vue&type=template&id=b8be3d86&scoped=true&"
import script from "./ExportFormatButton.vue?vue&type=script&lang=js&"
export * from "./ExportFormatButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8be3d86",
  null
  
)

export default component.exports