import { render, staticRenderFns } from "./FileChip.vue?vue&type=template&id=bb3b3748&scoped=true&"
import script from "./FileChip.vue?vue&type=script&lang=js&"
export * from "./FileChip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb3b3748",
  null
  
)

export default component.exports