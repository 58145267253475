import { render, staticRenderFns } from "./CurationExampleImage.vue?vue&type=template&id=c0177af8&scoped=true&"
import script from "./CurationExampleImage.vue?vue&type=script&lang=js&"
export * from "./CurationExampleImage.vue?vue&type=script&lang=js&"
import style0 from "./CurationExampleImage.vue?vue&type=style&index=0&id=c0177af8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0177af8",
  null
  
)

export default component.exports