import { render, staticRenderFns } from "./SubjectDetail.vue?vue&type=template&id=11ef29ae&scoped=true&"
import script from "./SubjectDetail.vue?vue&type=script&lang=js&"
export * from "./SubjectDetail.vue?vue&type=script&lang=js&"
import style0 from "./SubjectDetail.vue?vue&type=style&index=0&id=11ef29ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ef29ae",
  null
  
)

export default component.exports